<template>
  <div>
    <v-card flat elevation="0">
      <v-toolbar dense elevation="0">
        <!-- <v-toolbar-title>Sales Price</v-toolbar-title> -->
        <div style="max-width: 100px">
          <v-select
            :items="itemsPerPageOption"
            label="Show Items"
            dense
            v-model="datatable_options.itemsPerPage"
            style="max-width: 100px"
            hide-details
            single-line
          ></v-select>
        </div>
        <v-spacer></v-spacer>

        <v-text-field
          v-if="!isMobile"
          dense
          autofocus
          outlined
          rounded
          placeholder="Search"
          v-model="search"
          append-icon="mdi-magnify"
          style="max-width: 300px"
          hide-details
          single-line
        ></v-text-field>
        <rightbar>
          <template v-slot:activator="{ on }">
            <v-btn icon class="mx-1" v-on:click="on">
              <v-icon>mdi-filter</v-icon>
            </v-btn>
          </template>
        </rightbar>
        <v-btn icon @click="getData(true)" title="Refresh">
          <v-icon>mdi-sync</v-icon>
        </v-btn>
      </v-toolbar>

      <div class="px-2" v-if="isMobile">
        <v-text-field
          class="mb-2"
          dense
          clearable
          autofocus
          outlined
          placeholder="Search"
          v-model="search"
          append-icon="mdi-magnify"
          style="max-width: 3000px"
          hide-details
          single-line
        ></v-text-field>
      </div>
      <v-data-table
        :options.sync="datatable_options"
        :loading="datatable.loading"
        :loading-text="datatable.loadingText"
        :headers="datatable.columns"
        :items="datatable.data"
        :search="search"
        :hide-default-header="isMobile"
        :server-items-length="datatable.totalRows"
        :items-per-page="datatable_options.itemsPerPage"
        dense
        class="mb-3 my-0"
        hide-default-footer
        @page-count="pageCount = $event"
      >
        <template v-slot:[`item.item_no_`]="{ item }" v-if="!isMobile">
          <span>{{ item.item_no_ }}</span>
        </template>
        <template v-slot:[`item.unit_price`]="{ item }" v-if="!isMobile">
          <span>{{ numberFormat(item.unit_price, 2) }}</span>
        </template>
        <template v-slot:[`item.starting_date`]="{ item }" v-if="!isMobile">
          <span>{{ appDateFormat(item.starting_date, 2) }}</span>
        </template>
        <template v-slot:[`item.ending_date`]="{ item }" v-if="!isMobile">
          <span>{{ appDateFormat(item.ending_date, 2) }}</span>
        </template>
        <template v-slot:[`item`]="{ item }" v-if="isMobile">
          <v-card elevation="4" class="ma-1" outlined>
            <v-card-title class="text-h5 mb-1 pb-1">{{
              item.item_no_
            }}</v-card-title>
            <v-card-subtitle class="my-0 py-0 mb-3">
              {{ item.item_description }} <br />
              {{ item.unit_of_measure_code }} <br />
              {{ item.location_code }} | {{ item.bin_code }} <br />
              {{ item.quantity_base }}
            </v-card-subtitle>
          </v-card>
        </template>
        <v-alert slot="no-results" :value="true" color="error" icon="warning"
          >Your search for "{{ search }}" found no results.</v-alert
        >
      </v-data-table>
      <div class="text-right pt-2 mx-auto" style="max-width: 600px">
        <v-pagination
          v-model="datatable_options.page"
          :length="totalPage"
        ></v-pagination>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      elevations: [6, 12, 18],
      loading: null,
      search: "",
      datatable_options: {
        itemsPerPage: 20,
      },
      itemsPerPageOption: [10, 20, 50, 100, 500],
      totalPage: 0,
      datatable: {
        options: {},
        loading: null,
        loadingText: "Loading... Please wait...",
        totalRows: 0,
        columns: [
          { text: "Sales Type", value: "sales_type" },
          { text: "Sales Code", value: "sales_code" },
          { text: "Item No.", value: "item_no_" },
          { text: "Item Description", value: "item_description" },
          { text: "UoM Code", value: "unit_of_measure_code" },
          { text: "Unit Price", value: "unit_price" },
          { text: "Starting Date", value: "starting_date" },
          { text: "Ending Date", value: "ending_date" },
        ],
        data: [],
      },
    };
  },
  watch: {
    datatable_options: {
      handler() {
        this.getData();
      },
      deep: true,
    },

    search: {
      handler() {
        this.datatable_options.page = 1;
        this.getData();
      },
      deep: true,
    },
  },
  methods: {
    getData(refresh) {
      if (refresh) this.AxiosStorageRemove("GET", "salesprice/data");

      const { sortBy, sortDesc, page, itemsPerPage } = this.datatable_options;

      this.datatable.loading = true;
      var formData = new FormData();
      formData.append("search", this.search);
      formData.append("sort_by", sortBy);
      formData.append("sort_desc", sortDesc);
      formData.append("page", page);
      formData.append("limit", itemsPerPage);
      this.$axios
        .post("salesprice/data", formData)
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.datatable.data = resData.data.results;
            this.datatable.totalRows = resData.data.paging.total_rows;
            this.totalPage = resData.data.paging.total_page;
          } else {
            this.showAlert(resData.status, resData.message);
          }
          this.datatable.loading = false;
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.datatable.loading = false;
        });
    },
  },

  mounted() {
    this.getData();
  },
};
</script>
